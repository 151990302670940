@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@font-face {
		font-family: Poppins;
		font-weight: 100;
		src: url(./fonts/Poppins-Thin.ttf);
	}
	@font-face {
		font-family: Poppins;
		font-weight: 200;
		src: url(./fonts/Poppins-ExtraLight.ttf);
	}
	@font-face {
		font-family: Poppins;
		font-weight: 300;
		src: url(./fonts/Poppins-Light.ttf);
	}
	@font-face {
		font-family: Poppins;
		font-weight: 400;
		src: url(./fonts/Poppins-Regular.ttf);
	}
	@font-face {
		font-family: Poppins;
		font-weight: 500;
		src: url(./fonts/Poppins-Medium.ttf);
	}
	@font-face {
		font-family: Poppins;
		font-weight: 600;
		src: url(./fonts/Poppins-SemiBold.ttf);
	}
	@font-face {
		font-family: Poppins;
		font-weight: 700;
		src: url(./fonts/Poppins-Bold.ttf);
	}
	@font-face {
		font-family: Poppins;
		font-weight: 800;
		src: url(./fonts/Poppins-ExtraBold.ttf);
	}
	@font-face {
		font-family: Poppins;
		font-weight: 900;
		src: url(./fonts/Poppins-Black.ttf);
	}
}

html {
	scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
	width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #9f9f9f;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #1de8ff;
}
